import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Help, Email } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Clipboard from 'react-clipboard.js';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#421c52',
    },
    secondary: {
      main: '#9768ab',
    },
  },
});

const styles = {
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 20,
      width: '100%',
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    icon: {
      marginRight: 7,
    },
    paper: {
      padding: theme.spacing.unit * 2,
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    button: {
      marginRight: theme.spacing.unit,
    },
    inputHalf: {
      width: '48%',
    },
    card: {
      maxWidth: '100%',
    },
    actions: {
      display: 'flex',
      padding: '20px 24px',
      clear: 'both',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    signatureBox: {
      display: 'inline-block',
      width: '100%',
    },
    clipboardBtn: {
      border: 'none',
      padding: 0,
    },
  };


  
class AppSignature extends React.Component {

  state = { 
    expanded: false,
    name: 'John Doe, MA',
    position: 'Consultant / Berater',
    email: 'example@akern.at',
    mobile: '+43 111 222 33 444',
    phone: '+43 111 222 33 444',
    website: 'www.akern.at',
    address: 'Dresdner Strasse 47/3.OG, A-1200 Wien',
    facebookLink: 'https://www.facebook.com/AKERN.at/',
    xingLink: 'https://www.xing.com/companies/a-kernsteuerberatunggmbh',
    linkedinLink: 'https://www.linkedin.com/company/a-kern/',
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleChange = position => event => {
    this.setState({
      [position]: event.target.value,
    });
  };
  handleChange = email => event => {
    this.setState({
      [email]: event.target.value,
    });
  };
  handleChange = mobile => event => {
    this.setState({
      [mobile]: event.target.value,
    });
  };
  handleChange = phone => event => {
    this.setState({
      [phone]: event.target.value,
    });
  };
  handleChange = website => event => {
    this.setState({
      [website]: event.target.value,
    });
  };
  handleChange = address => event => {
    this.setState({
      [address]: event.target.value,
    });
  };
  handleChange = facebookLink => event => {
    this.setState({
      [facebookLink]: event.target.value,
    });
  };
  handleChange = xingLink => event => {
    this.setState({
      [xingLink]: event.target.value,
    });
  };
  handleChange = linkedinLink => event => {
    this.setState({
      [linkedinLink]: event.target.value,
    });
  };
  
    render() {
      const { classes } = this.props;

    return (
      <React.Fragment>
        <Helmet>
            <title>Well | Email signature generator</title>
            <meta name="description" content="Helmet application" />
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      <MuiThemeProvider theme={theme}>
      <CssBaseline />

        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              WELL
            </Typography>
            <Tooltip title="Send us message">
              <Button color="inherit" className={classes.buttonHelp} aria-hidden="true" aria-pressed="true" href="mailto:help@wellpromotion.ba">
                <Help className={classes.icon} />
                help@wellpromotion.ba
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <main>
        <div className={classes.root} style={{ padding: 24 }}>
          <Grid container spacing={24}>
            <Grid item xs>
              <Typography variant="h6" color="inherit" className={classes.grow} style={{ marginBottom: 20 }}>
                AKERN Email Signature
              </Typography>

              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Personal Information</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <form className={classes.container} noValidate autoComplete="off">
                      <TextField
                      id="standard-full-width"
                      label="Full Name"
                      style={{ margin: 8 }}
                      defaultValue="John Doe, MA"
                      fullWidth
                      margin="normal"
                      onChange={this.handleChange('name')}
                      InputLabelProps={{
                          shrink: true,
                      }}        
                      />
                      <TextField
                      id="standard-full-width"
                      label="Position"
                      style={{ margin: 8 }}
                      defaultValue="Consultant / Berater"
                      fullWidth
                      margin="normal"
                      onChange={this.handleChange('position')}
                      InputLabelProps={{
                          shrink: true,
                      }}        
                      />

                  </form>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Contact Information</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <form className={classes.container} noValidate autoComplete="off">
                        
                        <TextField
                        id="standard-name"
                        fullWidth
                        label="Website"
                        style={{ margin: 8 }}
                        defaultValue="www.akern.at"
                        margin="normal"
                        onChange={this.handleChange('website')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }} 
                        />
                        <TextField
                        id="standard-name"
                        fullWidth
                        label="Email"
                        style={{ margin: 8 }}
                        defaultValue="example@akern.at"
                        margin="normal"
                        onChange={this.handleChange('email')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><Email className={classes.icon} /></InputAdornment>,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }} 
                        />
                         <TextField
                        id="standard-name"
                        label="Mobile"
                        style={{ margin: 8 }}
                        defaultValue="+43 111 222 33 444"
                        margin="normal"
                        onChange={this.handleChange('mobile')}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }} 
                        />
                        <TextField
                        id="standard-name"
                        label="Phone"
                        style={{ margin: 8 }}
                        defaultValue="+43 111 222 33 444"
                        margin="normal"
                        onChange={this.handleChange('phone')}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}   
                        />
                        <TextField
                        id="standard-full-width"
                        label="Address"
                        style={{ margin: 8 }}
                        defaultValue="Dresdner Strasse 47/3.OG, A-1200 Wien"
                        fullWidth
                        margin="normal"
                        onChange={this.handleChange('address')}
                        InputLabelProps={{
                            shrink: true,
                        }}        
                        />

                    </form>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Social Media Links</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <form className={classes.container} noValidate autoComplete="off">
                      <TextField
                      id="standard-full-width"
                      label="Facebook"
                      style={{ margin: 8 }}
                      defaultValue="https://www.facebook.com/AKERN.at/"
                      fullWidth
                      margin="normal"
                      onChange={this.handleChange('facebookLink')}
                      InputLabelProps={{
                          shrink: true,
                      }}        
                      />
                      <TextField
                      id="standard-full-width"
                      label="Xing"
                      style={{ margin: 8 }}
                      defaultValue="https://www.xing.com/companies/a-kernsteuerberatunggmbh"
                      fullWidth
                      margin="normal"
                      onChange={this.handleChange('xingLink')}
                      InputLabelProps={{
                          shrink: true,
                      }}        
                      />
                      <TextField
                      id="standard-full-width"
                      label="LinkedIn"
                      style={{ margin: 8 }}
                      defaultValue="https://www.linkedin.com/company/a-kern/"
                      fullWidth
                      margin="normal"
                      onChange={this.handleChange('linkedinLink')}
                      InputLabelProps={{
                          shrink: true,
                      }}        
                      />

                  </form>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <div className={classes.formContainer}>
                
            </div>


            </Grid>
            <Grid item xs>
              <Typography variant="h6" color="inherit" style={{ marginBottom: 20 }}>
                  Preview
              </Typography>
              
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Email />
                  }
                  title="New mail"
                  style={{ borderBottom: '1px solid #f2f2f2', color: '#674974' }}
                />
                <CardHeader
                  style={{ borderBottom: '1px solid #f2f2f2' }}
                  subheader="To: David Smith"
                />
                <CardHeader
                  style={{ borderBottom: '1px solid #f2f2f2' }}
                  subheader="Subject: Check out my new email signature!"
                />
                <CardContent>
                  <Typography component="p" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    Hi David,
                    <br/><br/>
                    Check out this awesome email signature created by Well Business Promotion.
                    <br/><br/>
                    Regards,
                  </Typography>
                  <div className={classes.signatureBox}>
                    <div id="signatureAkern" style={{ marginTop: '20px' }}>
                      <table style={{ fontFamily: 'helvetica, sans-serif' }}>
                        <tr>
                          <td rowspan="5" style={{ verticalAlign: 'top' }}><img src="https://akern.at/images/akern-logo.png" alt="Akern" title={this.state.name + ' | ' + this.state.address} /></td>
                          <td style={{ paddingLeft: '7px' }}><h1 style={{ color: '#212121', fontSize: '12pt', margin: '0' }}>{this.state.name} <span style={{ color: '#212121', fontSize: '12pt', fontWeight: 'normal', margin: '0 0 5px 0' }}>| {this.state.position}</span></h1></td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: '7px', verticalAlign: 'middle' }}>
                            <p style={{ margin: '0px', marginTop: '-6px', fontSize: '8pt' }}>
                              <span style={{ color: '#421C52', fontSize: '8pt' }}>e:</span>&nbsp;<a style={{ textDecoration: 'none', color: '#777777', fontSize: '8pt' }} href={'mailto:' + this.state.email} title="Email me">{this.state.email}</a>&nbsp;
                              <span style={{ color: '#421C52', fontSize: '8pt' }}>m:</span>&nbsp;<a style={{ textDecoration: 'none', color: '#777777', fontSize: '8pt' }} href={'tel:' + this.state.mobile}  title="Call me">{this.state.mobile}</a>&nbsp;
                              <span style={{ color: '#421C52', fontSize: '8pt' }}>p:</span>&nbsp;<a style={{ textDecoration: 'none', color: '#777777', fontSize: '8pt' }} href={'tel:' + this.state.phone}  title="Call me">{this.state.phone}</a>&nbsp;<br/>
                              <span style={{ color: '#421C52', fontSize: '8pt' }}>w:</span>&nbsp;<a style={{ textDecoration: 'none', color: '#777777', fontSize: '8pt' }} href={'https://' + this.state.website} target="_blank" rel="noopener noreferrer" title="Visit our website">{this.state.website}</a>&nbsp;
                              <span style={{ color: '#421C52', fontSize: '8pt' }}>a:</span>&nbsp;<span style={{ textDecoration: 'none', color: '#777777', fontSize: '8pt' }}>{this.state.address}</span>
                            </p>
                          </td>
                        </tr>
                        <tr style={{ height: '28px' }}>
                          <td style={{ paddingLeft: '7px', height: '28px', verticalAlign: 'middle' }}>
                            <a href={this.state.facebookLink} target="_blank" rel="noopener noreferrer" title="Visit our Facebook page"><img style={{ height: '28px' }} src="https://akern.at/images/facebook-akern.png" alt="Visit our Facebook page" /></a>&nbsp;
                            <a href={this.state.xingLink} target="_blank" rel="noopener noreferrer" title="Visit our Xing page"><img style={{ height: '28px'}} src="https://akern.at/images/xing-akern.png" alt="Visit our Xing page" /></a>&nbsp;
                            <a href={this.state.linkedinLink} target="_blank" rel="noopener noreferrer" title="Visit our LinkedIn page"><img style={{ height: '28px' }} src="https://akern.at/images/linkedin-akern.png" alt="Visit our LinkedIn page" /></a>&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: '7px' }}>
                            <h2 style={{ color: '#777777', fontSize: '8pt', fontWeight: 'normal', margin: 0 }}>"A-KERN" Steuerberatung GmbH | FN 436336 x | Handelsgericht Wien</h2>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: '7px' }}>
                            <p style={{ margin: 0, marginTop: '-6px' }}><a style={{ fontSize: '5.5pt', color: '#421C52' }} href="http://akern.at/steuernews/?kicat=12&navcat=12" target="_blank" rel="noopener noreferrer" title="Subscribe to our Newsletter">Anmeldung zum kostenlosen Newsletter</a></p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" style={{ paddingTop: '20px', width: '200px' }}>
                            <span style={{ color: '#421C52', fontSize: '8pt' }}>VERTRAULICHKEITSHINWEIS</span>
                            <p style={{ color: '#bdbdbd', fontSize: '8pt', margin: 0 }}>Die Information in dieser Nachricht ist vertraulich und ausschließlich für den Adressaten bestimmt. Der Empfänger dieser Nachricht, der nicht der Adressat, einer seiner Mitarbeiter oder sein Empfangsbevollmächtigter ist, wird hiemit davon in Kenntnis gesetzt, daß er deren Inhalt nicht verwenden, weitergeben oder reproduzieren darf. Sollen Sie diese Nachricht irrtümlich erhalten haben, benachrichtigen Sie uns bitte unverzüglich per Telefon und löschen Sie die Nachricht von Ihrem System.</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" style={{ width: '200px' }}>
                            <span style={{ color: '#421C52', fontSize: '8pt' }}>CONFIDENTIALITY NOTICE</span>
                            <p style={{ color: '#bdbdbd', fontSize: '8pt', margin: 0 }}>The information contained in this e-mail is privileged and confidential and is for the exclusive use of the addressee. The person who receives this e-mail and who is not the addressee, one of his employees or an agent entitled to hand it over to the addressee, is informed that he may not use, disclose or reproduce the contents thereof. If you have received this communication by mistake, please let us know by telephone without delay and delete the message from your system.</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                  <Clipboard data-clipboard-target="#signatureAkern" className={classes.clipboardBtn}>
                    <Tooltip title="Copy your signature and paste into your favorite email client" placement="right">
                      <Button size="large" variant="contained" color="primary">
                        Copy Signature
                      </Button>
                    </Tooltip>
                  </Clipboard>
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    })}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                    aria-label="Help"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                  <Typography variant="h5" color="inherit" className={classes.grow} style={{ marginBottom: 20 }}>
                    HELP SECTION
                  </Typography>
                    <Typography variant="h6">
                      Outlook
                    </Typography>
                    <Typography paragraph>
                      - <a href="https://support.office.com/en-us/article/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2" target="_blank" rel="noopener noreferrer">Create and add a signature to messages</a>
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
              
            </Grid>
          </Grid>
        </div>
        </main>

        </MuiThemeProvider>
        </React.Fragment>
    );
  }
}
  
  AppSignature.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(AppSignature);